<template>
<!-- ======= Header ======= -->
<div>

    <nav  class="navbar navbar-expand-lg navbar-light bg-light my-header fixed-top">
        
        <a class="navbar-brand" href="#">
            <img src="../assets/img/impact-logo.png" class="logo-img">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0 my-n">
                <li class="nav-item active">
                    <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Videos</a>
                </li>
                 <li class="nav-item">
                    <a class="nav-link" href="#">Contact Us</a>
                </li>
                 <li v-if="uid" class="nav-item">
                    <router-link class="nav-link" to="/profile">Profile</router-link>
                </li>
                <li v-else class="nav-item">
                    <router-link class="nav-link" to="/login">Login</router-link>
                </li>
               
            </ul>
            
        </div>
    </nav>

</div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'Home',
    components: {

    },
    data() {
        return {
            userData: [],
            uid: localStorage.getItem('uid'),
        }
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    methods: {
        
        logout() {
            localStorage.removeItem('username')
            localStorage.removeItem('mobile')
            localStorage.removeItem('uid')
            localStorage.removeItem('usertype')
            localStorage.removeItem('email')

            localStorage.removeItem('user')
            localStorage.removeItem('token')
            localStorage.removeItem('latitude')
            localStorage.removeItem('longitude')
            //this.$router.go('/login')
            this.$router.push('/login');
            localStorage.removeItem('isAdmin')
        }

    }
}
</script>

<style scoped>
.new-logo {
    background-color: #fff;
    padding-left: 3% !important;
    padding-right: 3% !important;
}

.site-name {
    font-weight: bold;
    padding-top: 15px;
}

.mc-pointer {
    cursor: pointer;
}

.brand-logo {
    background-color: #fff;
    padding: 5% !important;
}
</style>
