<template>
<div id="app">
    <router-view />
</div>
</template>

<script>
import axios from "axios";

require("./assets/vendor/bootstrap/css/bootstrap.min.css");
require("./assets/vendor/icofont/icofont.min.css");
require("./assets/vendor/venobox/venobox.css");
require("./assets/vendor/owl.carousel/assets/owl.carousel.min.css");
require("./assets/css/style.css");

require("./assets/vendor/jquery/jquery.min.js");
require("./assets/vendor/bootstrap/js/bootstrap.bundle.min.js");
require("./assets/vendor/jquery.easing/jquery.easing.min.js");
/* require("./assets/vendor/php-email-form/validate.js"); */
require("./assets/vendor/isotope-layout/isotope.pkgd.min.js");
/*require("./assets/vendor/venobox/venobox.min.js");
require("./assets/vendor/owl.carousel/owl.carousel.min.js");
*/
require("./assets/js/main.js");


export default {
    components: {},
    data() {
        return {
            chknav: this.$route.name,
            ip_address: JSON.parse(localStorage.getItem("ip_address")),
        };
    },
    mounted() {
        // this.getConfig()
        // console.log(this.$route.name)
        // this.myIpData()
    },
    watch: {
        chknav: function (val) {
            this.chknav = val;
            // console.log("sasdas")
            // console.log(val)
        },
    },
    methods: {
        async getConfig() {
            const response = await axios.get(
                this.$root.URL_ROOT + "api.php?type=configdata"
            );
            this.$store.commit("getConfig", response.data);
            // console.log(this.$store.state.config)
        },
        myIpData() {
            var ip_address = JSON.parse(localStorage.getItem("ip_address"));

            if (ip_address == null) {
                var min = 10000000000;
                var max = 99999999999;
                var num = Math.floor(Math.random() * (max - min + 1)) + min;
                localStorage.setItem("ip_address", JSON.stringify(num));
                // console.log("new ip");
                // console.log(num);
            } else {
                // console.log("old ip(local storage)")
                // console.log(ip_address)
            }
        },
    },
};
</script>

<style>
@media only screen and (max-width: 600px) {
    .my-n{
        padding-left:5%  !important;
    }
    .nav-item{
        width: unset !important;
        text-align: unset  !important;

    }
    .navbar-nav{
        width: unset !important;
    }
    .mbl{
        display: block !important;
    }
    .filter-row{
        display: none !important;
    }
    .filter-btn{
        width: 100% !important;
    }
    .filter-mbl{
        text-align: center;
    }
}
.navbar-nav{
    width: 100%;
}
.nav-item{
    width:15%;
    text-align: center;

}
.hide{
    display: none;
}
#footer{
    margin-top: 10%;
}
.my-header{
    background-color: #67538d !important;
}
#header.header-scrolled{
    background-color: #67538d;
}
#footer{
    background-color: #67538d;
}
#hero:before{
    background: rgb(0 0 0 / 75%);
}
.filter-btn{
    background-color: #fff0;
    width: 40%;
    padding: 10px;
    border: 1px solid #a6bacc;
    border-radius: 5px;
}
.result-div{
    text-align-last: end;
    padding: 10px;
    padding-right: 10%;
    font-size: 20px;
}
.filter-row{
    padding: 30px;
}
.fillter-heading{
   color:black;
}
.fillter-ul{
   list-style-type: none;
   padding-left: unset;
}
.fillter-a{
    color:#67538d;
    cursor: pointer;
}
.fillter-box{
    display: none;
}
.dex-right{
    float: right;
}
.my-n{
    padding-left:25%;
}
.logo-img{
    height: 60px;
    background-color: #fff;
    padding: 10px;
}
.navbar-light .navbar-toggler{
    background-color: #fff;
}
.navbar-light .navbar-nav .active>.nav-link{
    color: #fff;
}
.navbar-light .navbar-nav .nav-link{
    color: #fff;
}
.mbl{
    display: none;
}
.filter-row{
    display: block;
}
.mbl-result-div{
    text-align: center;
    margin-bottom: 20px;
}
.mbl-filter-div{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
</style>
