<template>
<div>
    <Header />

    <div class="container main-dic">
        <div class="main-body">
            <div class="row">
                <div class="col-lg-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex flex-column align-items-center text-center">
                                <img src="https://bootdey.com/img/Content/avatar/avatar6.png" alt="Admin" class="rounded-circle p-1 bg-primary" width="110">
                                <div class="mt-3">
                                    <h4>{{fname}}</h4>
                                    <p class="text-secondary mb-1">{{email}}</p>
                                    <p class="text-muted font-size-sm">{{address}},{{city}},{{state}}, {{country}}</p>

                                </div>
                            </div>

                        </div>
                    </div>

					<div class="card mt-3">
                <ul class="list-group list-group-flush">
                  <li @click="check_page='personal'" :class="check_page=='personal'?'list-group-item d-flex justify-content-between align-items-center flex-wrap active':'list-group-item d-flex justify-content-between align-items-center flex-wrap'">
                    <h6 class="mb-0 ">
						Personal Information 
					</h6>
                  </li>
				  <li @click="check_page='change'" :class="check_page=='change'?'list-group-item d-flex justify-content-between align-items-center flex-wrap active':'list-group-item d-flex justify-content-between align-items-center flex-wrap'">
                    <h6 class="mb-0">
						Change Password
					</h6>
                  </li>
				  <li @click="logout" class="list-group-item d-flex justify-content-between align-items-center flex-wrap'">
                    <h6 class="mb-0">
						Logout
					</h6>
                  </li>
                 
                </ul>
              </div>
                </div>
                <div class="col-lg-8">
                    <div class="card" v-if="check_page=='personal'" >
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Full Name</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="text" class="form-control" v-model="fname">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Email</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="text" class="form-control" v-model="email">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Mobile</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="number" class="form-control" v-model="mobile">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Address</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="text" class="form-control" v-model="address">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">City</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="text" class="form-control" v-model="city">
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">State</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="text" class="form-control" v-model="state">
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Country</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="text" class="form-control" v-model="country">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="button" @click="updateUserDetail" class="btn btn-primary px-4" value="Save Changes">
                                </div>
                            </div>
                        </div>
                    </div>

					<div class="card" v-if="check_page=='change'" >
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Old Password</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="text" class="form-control" v-model="opass">
                                </div>
                            </div>

							<div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">New Password</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="text" class="form-control" v-model="npass">
                                </div>
                            </div>

							<div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Confirm Password</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="text" class="form-control" v-model="cpass">
                                </div>
                            </div>
                            

                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="button" @click="updatePassword" class="btn btn-primary px-4" value="Save Changes">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <Footer />

</div>
</template>

<style scoped>
@media only screen and (max-width: 600px) {
    .main-dic {
        margin-top: 25% !important;

    }
}

.main-dic {
    margin-top: 10%;
    margin-bottom: 15%;
}

.portfolio-items {
    background-color: #f3f1f1;
}

.video-name {
    padding: unset;
    margin: unset;
    color: black;
    font-size: 14px;
}

.video-address {
    padding: unset;
    margin: unset;
    font-size: 14px;
}

.video_detail {
    padding: 15px;
}

.star-icon {
    width: 16px;
    padding: 2px;
}

.set {
    margin-bottom: 40px;
}
</style>

<script>
import Header from '../components/Navbar'
import Footer from '../components/Footer'

import $ from 'jquery';
import axios from 'axios'

export default {
    components: {
        Header,
        Footer,

    },
    data() {
        return {
            box_temp: false,
            uid: localStorage.getItem("uid"),
            fname: null,
            email: null,
            mobile: null,
            address: null,
            city: null,
            state: null,
            country: null,

			check_page: 'personal',
			opass: null,
			npass: null,
			cpass: null,

        };
    },
    mounted() {
        this.getUserDetail();
    },
    methods: {
        filter_box() {
            if (this.box_temp) {
                $(".fillter-box").slideUp("slow");
                this.box_temp = false;
            } else {
                $(".fillter-box").slideDown("slow");
                this.box_temp = true;
            }

        },
        getUserDetail() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getUserDetail',
                    uid: this.uid,

                }
            }).then(function (response) {
                var data = response.data;
                console.log(data);
                ev.fname = data.username;
                ev.email = data.email;
                ev.mobile = data.mobile;
                ev.address = data.address;
                ev.city = data.city;
                ev.state = data.state;
                ev.country = data.country;
            })
        },
        updateUserDetail() {
            var ev = this;

            if (this.fname == null || this.fname == "") {
                ev.$toasted.global.error({
                    message: "please enter fullname"
                });
            } else if (this.email == null || this.email == "") {
                ev.$toasted.global.error({
                    message: "please enter email"
                });
            } else if (this.mobile == null || this.mobile == "") {
                ev.$toasted.global.error({
                    message: "please enter mobile no."
                });
            } else if (this.address == null || this.address == "") {
                ev.$toasted.global.error({
                    message: "please enter address"
                });
            } else if (this.city == null || this.city == "") {
                ev.$toasted.global.error({
                    message: "please enter city"
                });
            } else if (this.state == null || this.state == "") {
                ev.$toasted.global.error({
                    message: "please enter state"
                });
            } else if (this.country == null || this.country == "") {
                ev.$toasted.global.error({
                    message: "please enter country"
                });
            } else {

                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'edit_user_detail',
                        uid: this.uid,
                        fname: this.fname,
                        email: this.email,
                        mobile: this.mobile,
                        address: this.address,
                        city: this.city,
                        state: this.state,
                        country: this.country,

                    }
                }).then(function (response) {
                    var data = response.data;
                    console.log(data);
					if(data.status=='success'){
						ev.$toasted.global.success({
                  	  		message: data.msg
               			});
					}else{
						ev.$toasted.global.error({
                  	  		message: data.msg
               			});
					}
                })
            }
        },
		updatePassword(){
			 var ev = this;

            if (this.opass == null || this.opass == "") {
                ev.$toasted.global.error({
                    message: "please enter old password"
                });
            } else if (this.npass == null || this.npass == "") {
                ev.$toasted.global.error({
                    message: "please enter new password"
                });
            } else if (this.cpass == null || this.cpass == "") {
                ev.$toasted.global.error({
                    message: "please enter confirm password"
                });
            } else {
				axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'change_password',
                        uid: this.uid,
                        opass: this.opass,
						npass: this.npass,
						cpass: this.cpass,
                    }
                }).then(function (response) {
                    var data = response.data;
                    if(data.status=='success'){
						ev.$toasted.global.success({
                  	  		message: data.msg
               			});
						ev.opass=null;
						ev.npass=null;
						ev.cpass=null;

					}else{
						ev.$toasted.global.error({
                  	  		message: data.msg
               			});
					}
                })
			}
		},
		logout() {
            localStorage.removeItem('username')
            localStorage.removeItem('mobile')
            localStorage.removeItem('uid')
            localStorage.removeItem('usertype')
            localStorage.removeItem('email')

            localStorage.removeItem('user')
            localStorage.removeItem('token')
            localStorage.removeItem('latitude')
            localStorage.removeItem('longitude')
            //this.$router.go('/login')
            this.$router.push('/login');
            localStorage.removeItem('isAdmin')
        }
    }
}
</script>
