<template>
<div class="login-page1">
    <div class="container">
        <div class="row">
            <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div class="card card-signin my-5">
                    <div class="card-body">
                        <h5 class="card-title text-center">Sign In</h5>

                        <div class="form-label-group">
                            <input type="email" id="inputEmail" v-model="username" class="form-control" placeholder="Email address" required autofocus />

                        </div>
                        <div class="form-label-group">
                            <input type="password" v-model="password" id="inputPassword" class="form-control" placeholder="Password" required />

                        </div>

                        <button @click="login" class="btn btn-lg btn-primary btn-block text-uppercase" type="submit">
                            Sign in
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
:root {
    --input-padding-x: 1.5rem;
    --input-padding-y: 0.75rem;
}

.login-page {
    background: linear-gradient(to right, #0a346d, #1598ef);
}

.card-signin {
    border: 0;
    border-radius: 0rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-title {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.5rem;
}

.card-signin .card-body {
    padding: 2rem;
}

.form-signin {
    width: 100%;
}

.form-signin .btn {
    font-size: 80%;
    border-radius: 0rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    padding: 1rem;
    transition: all 0.2s;
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}

.form-label-group input {
    height: auto;
}

.form-label-group>input,
.form-label-group>label {
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
}

.form-control:focus {
    box-shadow: 10px 0px 0px 0px #ffffff !important;
}

.btn-google {
    color: white;
    background-color: #ea4335;
}

.form-control {
    padding: 10px !important;
}

.btn-facebook {
    color: white;
    background-color: #3b5998;
}
</style>

<script>
import axios from 'axios'
export default {
    name: 'Login',
    components: {

    },
    data() {
        return {
            username: null,
            password: null,
        }
    },
    mounted() {

    },
    methods: {
        login() {
            var ev = this;

            if (this.username == null || this.username == "") {
                ev.$toasted.global.error({
                    message: "please enter email"
                });
            } else if (this.password == null || this.password == "") {
                ev.$toasted.global.error({
                    message: "please enter password"
                });
            } else {

                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'login',
                        username: this.username,
                        password: this.password,
                    }
                }).then(function (response) {
                    var data = response.data;
                    console.log(data);
                    ev.userdata = data.session_data;
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        localStorage.setItem('username', ev.userdata.username);
                        localStorage.setItem('uid', ev.userdata.uid);
                        localStorage.setItem('mobile', ev.userdata.mobile);
                        localStorage.setItem('role', ev.userdata.role);
                    
                        ev.$router.push('/');
                       
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }

                })
            }
        }
    },
}
</script>
