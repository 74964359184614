<template>
<div>
    <Header />

    <!-- ======= Hero Section ======= -->
    <section id="hero">
        <div class="hero-container">
            <h1>Welcome to Impact School</h1>
            <h2>3,771 Customer Love Stories</h2>
            <h2>Students aren't just satisfied, they have real business results.
                Rated 4.9 Stars from 3,771 customer reviews.
                22% of the reviews include the word "love".</h2>
        </div>
    </section><!-- #hero -->

    <main id="main">

        <!-- ======= Our Portfolio Section ======= -->
        <section id="portfolio" class="portfolio">
            <div class="container">

                <div class="section-title">
                    <h2 class="hide">Our Portfolio</h2>
                </div>
                <div class="filter-row">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12">
                            <div class="filter-div">
                                <button class="filter-btn" @click="filter_box">Filters</button>
                            </div>
                        </div>

                        <div class="col-lg-6 col-12">
                            <div class="result-div">
                                <span class="span-result">1,232 results</span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="mbl">
                    <div class="mbl-result-div">
                        <span class="span-result">1,232 results</span>
                    </div>

                    <div class="mbl-filter-div">
                        <button class="filter-btn" @click="filter_box">Filters</button>
                    </div>

                </div>

                <div class="fillter-box">
                    <div class="row">
                        <div class="col-md-3 filter-mbl">
                            <h3 class="fillter-heading">
                                Ratings
                            </h3>
                            <ul class="fillter-ul">
                                <li><a class="fillter-a">5 Star</a></li>
                                <li><a class="fillter-a">4 Star</a></li>
                                <li><a class="fillter-a">3 Star</a></li>
                                <li><a class="fillter-a">2 Star</a></li>
                                <li><a class="fillter-a">1 Star</a></li>
                            </ul>
                        </div>

                        <div class="col-md-3 filter-mbl">
                            <h3 class="fillter-heading">
                                Results
                            </h3>
                            <ul class="fillter-ul">
                                <li><a class="fillter-a">7 Figures</a></li>
                                <li><a class="fillter-a">6 Figures</a></li>
                                <li><a class="fillter-a">5 Figures</a></li>
                                <li><a class="fillter-a">Making Money</a></li>
                                <li><a class="fillter-a">Starting</a></li>
                            </ul>
                        </div>

                        <div class="col-md-3 filter-mbl">
                            <h3 class="fillter-heading">
                                Category
                            </h3>
                            <ul class="fillter-ul">
                                <li><a class="fillter-a">Health & Wellness</a></li>
                                <li><a class="fillter-a">Marketing & eCommerce</a></li>
                                <li><a class="fillter-a">Education & Coaching</a></li>
                                <li><a class="fillter-a">Financial & Legal</a></li>
                                <li><a class="fillter-a">Construction Services</a></li>
                            </ul>
                        </div>

                        <div class="col-md-3 filter-mbl">
                            <h3 class="fillter-heading">
                                Location
                            </h3>
                            <ul class="fillter-ul">
                                <li><a class="fillter-a">North America</a></li>
                                <li><a class="fillter-a">Europe</a></li>
                                <li><a class="fillter-a">Latin America</a></li>
                                <li><a class="fillter-a">Asia</a></li>
                                <li><a class="fillter-a">Oceania</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row portfolio-container">

                    <div class="col-lg-4 col-md-6 filter-app">
                        <div class="portfolio-items">
                            <div class="video-blog">
                                <div style="padding:56.25% 0 0 0;position:relative;">
                                    <iframe src="https://player.vimeo.com/video/563329725?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Untitled"></iframe>
                                </div>
                            </div>
                            <div class="video_detail">
                                <div class="row">
                                    <div class="col-8">
                                        <p class="video-name">Dave Rogenmoser</p>
                                        <p class="video-address">Maryland, United States</p>
                                    </div>
                                    <div class="col-4 set">
                                        <img src="../assets/img/star.png" class="star-icon">
                                        <img src="../assets/img/star.png" class="star-icon">
                                        <img src="../assets/img/star.png" class="star-icon">
                                        <img src="../assets/img/star.png" class="star-icon">
                                        <img src="../assets/img/star.png" class="star-icon">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 filter-app">
                        <div class="portfolio-items">
                            <div class="video-blog">
                                <div style="padding:56.25% 0 0 0;position:relative;">
                                    <iframe src="https://player.vimeo.com/video/563329725?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Untitled"></iframe>
                                </div>
                            </div>
                            <div class="video_detail">
                                <div class="row">
                                    <div class="col-8">
                                        <p class="video-name">Dave Rogenmoser</p>
                                        <p class="video-address">Maryland, United States</p>
                                    </div>
                                    <div class="col-4 set">
                                        <img src="../assets/img/star.png" class="star-icon">
                                        <img src="../assets/img/star.png" class="star-icon">
                                        <img src="../assets/img/star.png" class="star-icon">
                                        <img src="../assets/img/star.png" class="star-icon">
                                        <img src="../assets/img/star.png" class="star-icon">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 filter-app">
                        <div class="portfolio-items">
                            <div class="video-blog">
                                <div style="padding:56.25% 0 0 0;position:relative;">
                                    <iframe src="https://player.vimeo.com/video/563329725?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
                                </div>
                            </div>
                            <div class="video_detail">
                                <div class="row">
                                    <div class="col-8">
                                        <p class="video-name">Dave Rogenmoser</p>
                                        <p class="video-address">Maryland, United States</p>
                                    </div>
                                    <div class="col-4 set">
                                        <img src="../assets/img/star.png" class="star-icon">
                                        <img src="../assets/img/star.png" class="star-icon">
                                        <img src="../assets/img/star.png" class="star-icon">
                                        <img src="../assets/img/star.png" class="star-icon">
                                        <img src="../assets/img/star.png" class="star-icon">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section><!-- End Our Portfolio Section -->

        <button type="button" class="btn btn-primary">
            Launch demo modal
        </button>

        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title h4" id="exampleModalLgLabel">Large modal</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">...</div>
                </div>
            </div>
        </div>

    </main><!-- End #main -->

    <Footer />

</div>
</template>

<style scoped>
.portfolio-items {
    background-color: #f3f1f1;
}

.video-name {
    padding: unset;
    margin: unset;
    color: black;
    font-size: 14px;
}

.video-address {
    padding: unset;
    margin: unset;
    font-size: 14px;
}

.video_detail {
    padding: 15px;
}

.star-icon {
    width: 16px;
    padding: 2px;
}

.set {
    margin-bottom: 40px;
}
</style>

<script>
import Header from '../components/Navbar'
import Footer from '../components/Footer'

import $ from 'jquery';

export default {
    components: {
        Header,
        Footer,

    },
    data() {
        return {
            box_temp: false,
        };
    },
    mounted() {},
    methods: {
        filter_box() {
            if (this.box_temp) {
                $(".fillter-box").slideUp("slow");
                this.box_temp = false;
            } else {
                $(".fillter-box").slideDown("slow");
                this.box_temp = true;
            }

        }
    }
}
</script>
